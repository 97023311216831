import React from "react";
import Layout from "../../components/layout";
import Container from "../../components/container";
import Icon from "../../components/icon";
import Phone from "../../components/icon/icons/phone.icon";
import MapMarker from "../../components/icon/icons/map-marker.icon";
import Envelope from "../../components/icon/icons/envelope.icon";

const AboutPage = props => (
  <Layout pageTitle="About">
    <main>
      <Container verticalPadding={false} maxWidth={1000}>
        <h1 className="tc">Contact Us</h1>
        <p className="mb4">We would love to hear from you - please contact us in one of the following ways to learn
          more about Video First.</p>

        <div className="flex flex-row mb3">
          <div className="w-100 w-third-ns">
            <div className="center" style={{ width: "36px" }}>
              <Icon icon={Phone} width={36} height={36} fill="#454545" />
            </div>
          </div>
          <div className="w-100 w-third-ns">
            <div className="center" style={{ width: "36px" }}>
              <Icon icon={MapMarker} width={36} height={36} fill="#454545" />
            </div>
          </div>
          <div className="w-100 w-third-ns">
            <div className="center" style={{ width: "36px" }}>
              <Icon icon={Envelope} width={36} height={36} fill="#454545" />
            </div>
          </div>
        </div>
        <div className="flex flex-row tc">
          <div className="w-100 w-third-ns">
            <a href="tel:+447708894989" className="db mt-3">+44 (0) 7708 894989</a>
          </div>
          <div className="w-100 w-third-ns">
            <address className="fs-normal">
            Video First Software Ltd
            <br/>
            The Ormeau Baths
            <br/>
            18 Ormeau Avenue
            <br/>
            Belfast
            <br/>
            BT2 8HS
            </address>
          </div>
          <div className="w-100 w-third-ns">
            <a href="mailto:info@videofirst.io" className="ml-2">info@videofirst.io</a>
          </div>
        </div>
      </Container>
      <iframe className="mt4"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2311.6537132917915!2d-5.931225083804305!3d54.59247558834145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486108f909681395%3A0xb65f9aac7221d01d!2sOrmeau+Baths+Gallery!5e0!3m2!1sen!2suk!4v1547226762191"
        width="100%" height="400" frameBorder="0"></iframe>
    </main>
    <
    /Layout>
    );

    export default AboutPage;
